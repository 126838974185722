import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Portfolio = makeShortcode("Portfolio");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Portfolio mdxType="Portfolio">
      <p>{`👋 I’m Hal, a software engineer living in Brooklyn and building apps for iOS.`}</p>
      <p>{`Currently I’m an iOS engineer at `}<a parentName="p" {...{
          "href": "https://robinhood.com/"
        }}>{`Robinhood`}</a>{`. Previously, I led development of `}<a parentName="p" {...{
          "href": "https://qz.com/1724663/putting-members-at-the-heart-of-quartz/"
        }}>{`Quartz’s relaunched news app`}</a>{`, and before that, I worked on Quartz’s `}<a parentName="p" {...{
          "href": "https://qz.com/613700/its-here-quartzs-first-news-app-for-iphone/"
        }}>{`conversational news app`}</a>{`.`}</p>
      <p>{`Here’re some projects I’ve worked on recently.`}</p>
    </Portfolio>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      